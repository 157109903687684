<template>
   <div>
    <v-menu ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
        >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="time"
            :label="label"
            prepend-icon="event"
            readonly
            clearable
            v-bind="attrs"
            v-on="on"
            :rules="rules"
            ></v-text-field>
        </template>
        <v-time-picker
             v-model="time"
             format="24hr"
             full-width 
             >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(time)">OK</v-btn>
        </v-time-picker>
    </v-menu>
    </div>
</template>


<script>
import moment from "moment"; 
export default {
    name: 'TimeAdapter',
    props: {
            value: {
                required: true
            },
            label: {
                type: String,
                required: true
            },
            rules: {
            }
        },
    data() {
        return {
            menu: false,
            time: null,
        };
    },
    mounted(){
        this.time=this.formatTime();
    },
    watch: {
            time() {
                const newDate= this.formatDate();
                this.$emit('input',newDate);
            }
    },
    methods: {
        formatTime () {
            if (!this.value) return null
            return  moment(this.value).format("HH:mm")
        },
        formatDate () {
            if (!this.time) return null
            return moment(this.time,"HH:mm").format("YYYY-MM-DDTHH:mm:ss.000Z")
        },
  },
}
</script>